<template>
  <div class="main">
    <div class="header">
      <div style="height: 2rem"></div>
      <van-row  type="flex" justify="left">
        <van-col span="1" >
<!--          <div style="margin-left: 2rem;width: 80px;height: 80px;background-color: #00678F">-->
<!--          <van-image-->
<!--            width="80"-->
<!--            height="80"-->
<!--            src="https://img01.yzcdn.cn/vant/cat.jpeg"-->
<!--        />-->
<!--        </div>-->
    </van-col>
        <van-col span="12">
          <van-row>
            <van-col span="12">
              <div style="height: 80px;line-height: 80px; width: 80px;background: #fff;border-radius: 50%;color:#059bc9; font-size: 40px;">{{name.substring(0, 1)}}</div>
            </van-col>
            <van-col span="12">
              <div style="margin-top: 10px;">
                <div style="font-size: 1.2rem;font-weight:bolder;text-align: left;margin-bottom: 10px;">{{name}}</div>
                <div style="font-size: .875rem;text-align: left" v-if="userType==50">管理员</div>
                <div style="font-size: .875rem;text-align: left" v-if="userType==40">检查员</div>
              </div>
            </van-col>
          </van-row>
        </van-col>
        <van-col span="7" @click="logout()" style="padding-top: 10px;text-align: right;">退出登录</van-col>
      </van-row>
    </div>

    <div class="cell" v-if="userType==50">
      <van-cell value="检查员管理" is-link size="large" to="Inspector" />
    </div>
    <div style="height: 50px"></div>
    <div class="footer_menu">
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" @click="goHome">首页</van-tabbar-item>
        <van-tabbar-item icon="friends-o">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>

</template>
<script>
import { getLogout } from '@/api/get'
import Vue from 'vue';
import {Button, col, row, Tabbar, TabbarItem, Image, Cell, CellGroup, Toast} from 'vant'
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Image);
Vue.use(Cell);
Vue.use(CellGroup);
export default {
  components:{
    [Button.name]: Button,
    'van-col': col,
    'van-row': row,
  },
  data() {
    return {
      name:'',
      userType: 40,
      active: 1,
    };
  },
  mounted() {
    this.name=localStorage.name
    this.userType=localStorage.userType
    //this.queue(this.$route.meta.id,this.$route.meta.title,this.$route.path)
    },
  methods: {
    logout(){
      Toast('正在退出...')
      getLogout().then(res => {
        if (res.data.code === 200){
          localStorage.clear()
          this.$router.push({ path: '/login', query: {id:1}})
        }else{
          Toast.fail(res.data.msg)
        }
      }).catch(err => {
        console.log(err)
        localStorage.clear()
        this.$router.push({ path: '/login', query: {id:1}})
      })
    },
    goHome(){
      this.$router.push({ path: '/', query: {}})
    },
    history(t) {
    this.listType=t
    },
    xiafa(id) {
      this.$router.push({ path: '/xiafa', query: {id:id}})
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />